const replaceUrlStructureData = data => {
  if (!data) return;

  const {
    type,
    term,
    heading,
    label_ids,
    category_id,
    is_search: isSearch,
    is_nearby: isNearby,
    page_content: seoInfo,
    status_code: statusCode,
    is_valid_path: isValidUrl,
    location_metadata: metaData,
    province_code: provinceCode,
    is_available_now: isAvailableNow,
    selected_settlement: selectedCity,
    filters_hash: selectedFiltersHash,
    selected_province: selectedProvince,
    is_valid_path_structure: isValidPath,
  } = data;

  return {
    type,
    term,
    ...seoInfo,
    heading,
    metaData,
    isNearby,
    isSearch,
    label_ids,
    isValidUrl,
    statusCode,
    isValidPath,
    category_id,
    provinceCode,
    selectedCity,
    isAvailableNow,
    selectedProvince,
    selectedFiltersHash,
  };
};

export default replaceUrlStructureData;
